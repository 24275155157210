/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "bootstrap/dist/css/bootstrap.min.css";

@import "./paper-kit/variables";
@import "./paper-kit/mixins";

@import "./paper-kit/typography";

// Core CSS
@import "./paper-kit/misc";
@import "./paper-kit/buttons";
@import "./paper-kit/inputs";
@import "./paper-kit/progress-bars";
@import "./paper-kit/sliders";
@import "./paper-kit/alerts";
@import "./paper-kit/labels";
@import "./paper-kit/tooltips-and-popovers";
@import "./paper-kit/sections";
@import "./paper-kit/checkbox-radio";
@import "./paper-kit/navbars";
@import "./paper-kit/images";
@import "./paper-kit/cards";
@import "./paper-kit/footers";

// Bootstrap Switch
@import "./paper-kit/bootstrap-switch";

// Fancy Stuff
@import "./paper-kit/dropdown";
//
// icons
@import "./paper-kit/icons";
//
@import "./paper-kit/tabs-navs-pagination";
@import "./paper-kit/collapse";
@import "./paper-kit/carousel";
@import "./paper-kit/modal";
//
@import "./paper-kit/responsive";
@import "./paper-kit/plugins/datetimepicker";
@import "./paper-kit/social-buttons";
@import "./paper-kit/examples";
//
@import "./nucleo-icons.css";
@import "./paper-kit-2.css";

@font-face {
  font-family: "nucleo-icons";
  src: url("../../assets/fonts/nucleo-icons.eot");
  src: url("../../assets/fonts/nucleo-icons.eot") format("embedded-opentype"),
    url("../../assets/fonts/nucleo-icons.woff2") format("woff2"),
    url("../../assets/fonts/nucleo-icons.woff") format("woff"),
    url("../../assets/fonts/nucleo-icons.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Sortable Table (asc/desc caret) */
th[sortable] {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

th[sortable].desc:before,
th[sortable].asc:before {
  content: "";
  display: block;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC")
    no-repeat;
  background-size: 16px;
  width: 16px;
  height: 16px;
  float: left;
  margin-left: -16px;
  margin-top: 4px;
}

th[sortable].desc:before {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}
