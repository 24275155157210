/*          Changes for small display      */

@media (max-width: 767px){
    .navbar-transparent{
        // padding-top: 15px;
        background-color: rgba(0, 0, 0, 0.45);
    }
    body {
         position: relative;
         font-size: $font-size-small;
    }
    h6{
        font-size: 1em;
    }
    .navbar .container{
         left: 0;
          width: 100%;
         @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
         position: relative;
    }
    .navbar .navbar-nav .dropdown-menu{
        height: 400px;
        overflow-y: scroll;
    }
    .navbar .navbar-collapse.collapse,
    .navbar .navbar-collapse.collapse.in,
    .navbar .navbar-collapse.collapsing{
        // display: none !important;
    }
    .demo-header .motto{
        padding-top: 30% !important;
    }
    .navbar-toggle .icon-bar {
          display: block;
          position: relative;
          background: #fff;
          width: 24px;
          height: 2px;
          border-radius: 1px;
          margin: 0 auto;
    }

    .fog-low{
        margin-bottom: -35px;
    }
    .presentation-title{
        font-size: 5em;
    }
    .presentation-subtitle{
        margin-top: 40px;
    }
    .title-brand{
        max-width: 450px;
        .type{
            font-size: 16px;
        }
    }

    .navbar-header .navbar-toggle {
        margin-top: 12px;
        width: 40px;
        height: 40px;
    }
    .bar1,
    .bar2,
    .bar3 {
      outline: 1px solid transparent;
    }
    .bar1 {
      top: 0px;
      @include bar-animation($topbar-back);
    }
    .bar2 {
      opacity: 1;
    }
    .bar3 {
      bottom: 0px;
      @include bar-animation($bottombar-back);
    }
    .toggled .bar1 {
      top: 6px;
      @include bar-animation($topbar-x);
    }
    .toggled .bar2 {
      opacity: 0;
    }
    .toggled .bar3 {
      bottom: 6px;
      @include bar-animation($bottombar-x);
    }

    @include topbar-x-rotation();
    @include topbar-back-rotation();
    @include bottombar-x-rotation();
    @include bottombar-back-rotation();

    @-webkit-keyframes fadeIn {
      0% {opacity: 0;}
      100% {opacity: 1;}
    }
    @-moz-keyframes fadeIn {
      0% {opacity: 0;}
      100% {opacity: 1;}
    }
    @keyframes fadeIn {
      0% {opacity: 0;}
      100% {opacity: 1;}
    }

    .navbar-nav {
        margin: 1px -15px;

        .open .dropdown-menu > li {
            & > a{
                padding: 15px 15px 5px 50px;
            }

            &:first-child > a{
                padding: 5px 15px 5px 50px;
            }

            &:last-child > a {
                padding: 15px 15px 25px 50px;
            }
        }
    }

    [class*="navbar-"] .navbar-nav {
        & > li > a, > li > a:hover, > li > a:focus, .active > a, .active > a:hover, .active > a:focus, .open .dropdown-menu > li > a, .open .dropdown-menu > li > a:hover, .open .dropdown-menu > li > a:focus, .navbar-nav .open .dropdown-menu > li > a:active {
            color: white;

        }

        & > li > a:not(.btn),
        > li > a:hover,
        > li > a:focus,
        .open .dropdown-menu > li > a:not(.btn),
        .open .dropdown-menu > li > a:hover,
        .open .dropdown-menu > li > a:focus{
            opacity: .7;
            background: transparent;
        }

        &.navbar-nav .open .dropdown-menu > li > a:active {
            opacity: 1;
        }

        & .dropdown > a{
            &:hover .caret {
                border-bottom-color: #777;
                border-top-color: #777;
            }
            &:active .caret {
                border-bottom-color: white;
                border-top-color: white;
            }
        }

    }

    .bootstrap-datetimepicker-widget{
        visibility: visible;
    }

    .dropdown-menu {
        display: none;
    }
    .navbar-fixed-top {
        -webkit-backface-visibility: hidden;
    }

    .social-line .btn{
        margin: $margin-bottom;
    }
    .subscribe-line .form-control{
        margin: $margin-bottom;
    }
    .social-line.pull-right{
        float: none;
    }
    .footer nav.pull-left{
        float: none !important;
    }
    .footer:not(.footer-big) nav > ul li{
        float: none;
    }
    .footer{
        .footer-nav,
        .credits{
            margin: 0 auto;
        }
    }
    .section-buttons{
        .input-group{
            margin-top: 15px;
        }
        .form-group.has-danger{
            .form-control-feedback{
                margin-top: 5px;
            }
        }
    }
    .social-area.pull-right{
        float: none !important;
    }
    .form-control + .form-control-feedback{
        margin-top: -8px;
    }
    .navbar-toggle:hover,.navbar-toggle:focus {
        background-color: transparent !important;
    }
    .btn.dropdown-toggle{
        margin-bottom: 0;
    }
    .media-post .author{
        width: 20%;
        float: none !important;
        display: block;
        margin: 0 auto 10px;
    }
    .media-post .media-body{
        width: 100%;
    }
    .modal-footer .btn-simple {
        padding: 15px;
    }
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-x: scroll;
        overflow-y: hidden;
        border: 1px solid #dddddd;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
    }
    .typography-line{
        padding-left: 100px !important;
    }

    .projects-1{
        & .project-pills{
            & .nav.nav-pills{
                display: block !important;
                .nav-item{
                    &:first-child .nav-link{
                        border-radius: 12px 12px 0 0 !important;
                        margin-left: -1px;
                    }
                    &.active:first-child .nav-link{
                        border-radius: 12px 12px 0 0 !important;
                        margin-left: -1px;
                    }

                    &:last-child .nav-link{
                        border-radius: 0 0 12px 12px !important;
                        margin-left: -1px;
                    }
                }
            }
        }
    }
    .testimonials-2,
    .section-testimonials{
        .testimonials-people{
            img{
                display: none !important;
            }
        }
    }
    .presentation-page{
        .section-cards,
        .section-components{
            overflow: hidden;
            .first-card{
                top: 750px !important;
                .grid__link{
                    width: 200px !important;
                }
            }
            .fourth-card{
                top: 940px !important;
                .grid__link{
                    width: 200px !important;
                }
            }
            .fifth-card{
                top: 950px !important;
                left: 220px !important;
                .grid__link{
                    width: 200px !important;
                }
            }
            .sixth-card{
                top: 1335px !important;
                left: 220px !important;
                .grid__link{
                    width: 200px !important;
                }
            }
            .seventh-card {
                top: 1155px !important;
                .grid__link{
                    width: 200px !important;
                }
            }
        }
        .section-content{
            .image-container{
                .add-animation{
                    height: 250px !important;
                    width: 180px !important;
                }
            }
        }

        .section-components{
            .image-container{
                .components-macbook{
                    width: 580px !important;
                    height: 400px !important;
                }
                .social-img,
                .share-btn-img{
                    display: none;
                }
            }
            .title{
                margin-top: -100px !important;
            }
        }

        .section-examples{
            padding-top: 0 !important;
            margin-top: 10px !important;
        }

        .section-icons{
            .icons-nucleo{
                .nc-icon:not(.ninth-left-icon):not(.seventh-left-icon):not(.third-left-icon){
                    display: none !important;
                }
            }
        }
    }
    #typography{
        .typography-line{
            .note{
                margin-left: 7px !important;
            }
        }
    }
    .section-nucleo-icons{
        [class*='col-lg']{
            text-align: center!important;
            margin-bottom: 2em;
        }
    }
}
@media screen and (max-width: 991px){
    .navbar-collapse {
        display: block;
        position: fixed;
        top: 0;
        height: 100%;
        width: 230px;
        right: 0;
        z-index: 1032;
        visibility: visible;
        background-color: #999;
        overflow-y: visible;
        border-top: none;
        text-align: left;
        border-left: 1px solid #CCC5B9;
        padding-right: 0px;
        padding-left: 40px;
        padding-top: 15px;

        @include transform-translate-x(230px);
        @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
        ul {
            position: relative;
            z-index: 3;
            // overflow-y:scroll;
            height: 95%;
        }
        .navbar-nav > .nav-item{
            // border-bottom: 1px solid;
            &:last-child {
            border-bottom: 0;
            }
            & > .nav-link{
                margin: 0px 0px;
                color: $dark-gray !important;
                text-transform: uppercase;
                font-weight: 600;
                font-size: $font-size-small;
                line-height: $line-height-general;
                padding: 15px 0;
                &:hover,
                &:active{
                    color: $default-states-color !important;
                }
            }
        }
        &::after{
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            background-color: $bg-nude;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(112, 112, 112, 0) 60%, rgba(186, 186, 186, 0.15) 100%);
            display: block;
            content: "";
            z-index: 1;
        }
        &.has-image::after{
            @include black-filter(.8);
        }
    }
    .nav-open{
        & .navbar-collapse{
            @include transform-translate-x(0px);
            display: block;
        }
        & .wrapper{
            left: 0;
            @include transform-translate-x(-230px);
        }
        & .navbar-translate{
            @include transform-translate-x(-230px);
        }

    }
    .wrapper .navbar-collapse{
        display: none;
    }

    .fixed-top .navbar-collapse{
        background-color: #FF8F5E;
        // width: 100%;
        float: left;
    }

    .dropdown.show .dropdown-menu,
    .dropdown .dropdown-menu{
        background-color: transparent;
        border: 0;
        transition: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        width: auto;
        border-radius: 0;


        .dropdown-item:hover,
        .dropdown-item:focus{
            background-color: transparent;
            border-radius:0;
        }

        .dropdown-item a{

            &:hover,
            &:focus{
                color: $default-states-color;
            }
        }

        &:before,
        &:after{
            display: none;
        }
    }

    .dropdown{
        .dropdown-menu{
           display: none;
       }

       &.show .dropdown-menu{
           display: block;
       }
    }

    .navbar-translate{
        justify-content: space-between;
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
        @include transform-translate-x(0);
        @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
    }
    .wrapper{
    //    @include transform-translate-x(0px);
       @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
       left: 0;
       background-color: white;
    }
}

@media screen and (min-width:767px){
    .section-sections{
        .section-cols{
            position: relative;
            z-index: 1;
            .row{
                &:first-of-type{
                    margin-top: 50px;
                    margin-right: -120px;
                    margin-left: -15px;
                    @include transform-translate-x(-80px);
                }
                &:nth-of-type(2){
                    margin-left: -100px;
                    margin-right: -15px;
                    transform: translateX(80px);
                }
                &:nth-of-type(3){
                    margin-right: -120px;
                    margin-left: 0px;
                    transform: translateX(-120px);
                }
                &:nth-of-type(4){
                    margin-right: -100px;
                    margin-left: -15px;
                    transform: translateX(-50px);
                }
            }

        }
    }
}

@media screen and (min-width:991px){
    .burger-menu{
        .navbar-collapse {
            position: fixed;
            display: block;
            top: 0;
            height: 100%;
            width: 230px;
            right: 0px;
            z-index: 1032;
            visibility: visible;
            background-color: #999;
            overflow-y: visible;
            border-top: none;
            text-align: left;
            border-left: 1px solid #CCC5B9;
            padding-right: 0px;
            padding-left: 40px;
            padding-top: 15px;
            @include transform-translate-x(230px);
            @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));

            .navbar-nav .nav-item{
                &:last-child {
                    border-bottom: 0;
                }
            }
            .navbar-nav{
                height: 100%;
                z-index: 2;
                position: relative;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
            }

            &::after{
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                position: absolute;
                background-color: $bg-nude;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(112, 112, 112, 0) 60%, rgba(186, 186, 186, 0.15) 100%);
                display: block;
                content: "";
                z-index: 1;
            }
            &.has-image::after{
                @include black-filter(.8);
            }
        }
        .navbar{
            .container{
                .navbar-toggler{
                    display: block;
                    margin-top: 20px;
                }
            }
        }

        .navbar-translate{
            width: 100%;
            position: relative;
            @include transform-translate-x(0);
            @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
        }

        .wrapper{
            //    @include transform-translate-x(0px);
            @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
            left: 0;
            background-color: white;
        }

        .body{
            .navbar-collapse.collapse{
                height: 100vh !important;
            }
        }
        .navbar-collapse,
        .navbar-nav{
            &:before,
            &:after{
                display: table;
                content: " ";
            }
        }

        .nav-open &{
            .navbar-collapse.collapse{
                @include transform-translate-x(0px);
            }
            .wrapper{
                left: 0;
                @include transform-translate-x(-230px);
            }
            .navbar-translate{
                @include transform-translate-x(-230px);
            }
        }

        .dropdown.show .dropdown-menu,
        .dropdown .dropdown-menu{
            background-color: transparent;
            border: 0;
            transition: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            width: auto;
            border-radius: 0;


            .dropdown-item:hover,
            .dropdown-item:focus{
                background-color: transparent;
                border-radius:0;
            }

            .dropdown-item a{

                &:hover,
                &:focus{
                    color: $default-states-color;
                }
            }

            &:before,
            &:after{
                display: none;
            }
        }

        .dropdown{
            .dropdown-menu{
               display: none;
           }

           &.show .dropdown-menu{
               display: block;
           }
        }
    }
}
@media screen and (max-width: 1025px){
    .input-group{
        .input-group-addon{
            padding-right: 10px;
        }
    }
}
