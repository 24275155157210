/*!

 =========================================================
 * Paper Kit 2 Angular - v1.4.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/paper-kit-2-angular
 * Copyright 2020 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/timcreative/paper-kit/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
.tim-row {
  margin-bottom: 20px;
}

.tim-white-buttons {
  background-color: #777777;
}

.tim-title {
  margin-top: 30px;
  margin-bottom: 25px;
  min-height: 32px;
}

.tim-title.text-center {
  margin-bottom: 50px;
}

.typography-line {
  padding-left: 180px;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
}

.typography-line .note {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  margin-left: 20px;
  position: absolute;
  width: 260px;
}

.tim-row {
  padding-top: 50px;
}

.tim-row h3 {
  margin-top: 0;
}

.switch {
  margin-right: 20px;
}

#navbar-full .navbar {
  border-radius: 0 !important;
  margin-bottom: 0;
  z-index: 2;
}

.space {
  height: 130px;
  display: block;
}

.space-110 {
  height: 110px;
  display: block;
}

.space-50 {
  height: 50px;
  display: block;
}

.space-70 {
  height: 70px;
  display: block;
}

.navigation-example .img-src {
  background-attachment: scroll;
}

.main {
  background-color: #fff;
  /*     position: relative; */
}

.navigation-example {
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  margin-top: 0;
}

#notifications {
  background-color: #ffffff;
  display: block;
  width: 100%;
  position: relative;
}

#carousel {
  padding-top: 0;
}

.note {
  text-transform: capitalize;
}

.subscribe-form {
  padding-top: 20px;
}

.page-header .card-register .title {
  margin-bottom: 10px;
}

.space-100 {
  height: 100px;
  display: block;
  width: 100%;
}

.sharing-area .btn {
  padding: 0.5rem 10px !important;
}

.be-social {
  padding-bottom: 20px;
  /*     border-bottom: 1px solid #aaa; */
  margin: 0 auto 40px;
}

.txt-white {
  color: #ffffff;
}

.txt-gray {
  color: #ddd !important;
}

.footer {
  background-attachment: fixed;
  position: relative;
  line-height: 20px;
}

.footer nav > ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: normal;
}

.footer nav > ul > li {
  display: inline-block;
  padding: 10px 15px;
  margin: 15px 3px;
  line-height: 20px;
  text-align: center;
}

.footer nav > ul a:not(.btn) {
  color: #777777;
  display: block;
  margin-bottom: 3px;
}

.footer nav > ul a:not(.btn):hover,
.footer nav > ul a:not(.btn):focus {
  color: #e3e3e3;
}

.footer .copyright {
  color: #777777;
  padding: 10px 15px;
  font-size: 14px;
  margin: 15px 3px;
  line-height: 20px;
  text-align: center;
}

.footer .heart {
  color: #eb5e28;
}

.social-share {
  float: left;
  margin-right: 8px;
}

.social-share a {
  color: #ffffff;
}

#subscribe_email {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
}

.pick-class-label {
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  margin-right: 10px;
  padding: 23px;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

.parallax {
  width: 100%;
  height: 570px;
  display: block;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.logo-container .logo {
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #333333;
  width: 50px;
  float: left;
}

.logo-container .brand {
  font-size: 18px;
  color: #ffffff;
  line-height: 20px;
  float: left;
  margin-left: 10px;
  margin-top: 5px;
  width: 75px;
  height: 50px;
}

.logo-container {
  margin-top: 5px;
}

.logo-container .logo img {
  width: 100%;
}

.navbar-small .logo-container .brand {
  color: #333333;
}

.demo-header {
  background-size: cover;
  /*background-color: #FF8F5E;*/
  background-position: center top;
  margin-top: -100px;
  min-height: 600px;
}

.demo-height {
  min-height: 102vh;
}

.demo-height .motto {
  padding-top: 20% !important;
}

.demo-header .motto {
  color: #ffffff;
  padding-top: 15%;
  text-align: center;
  z-index: 3;
}

.demo-header .motto h3 {
  margin-bottom: 0;
}

.separator {
  content: "Separator";
  color: #ffffff;
  display: block;
  width: 100%;
  padding: 20px;
}

.separator-line {
  background-color: #eee;
  height: 1px;
  width: 100%;
  display: block;
}

.separator.separator-gray {
  background-color: #eeeeee;
}

.social-buttons-demo .btn {
  margin-right: 5px;
  margin-bottom: 7px;
}

.img-container {
  width: 100%;
  overflow: hidden;
}

.img-container img {
  width: 100%;
}

.lightbox img {
  width: 100%;
}

.lightbox .modal-content {
  overflow: hidden;
}

.lightbox .modal-body {
  padding: 0;
}

@media screen and (min-width: 991px) {
  .lightbox .modal-dialog {
    width: 960px;
  }
}

@media screen {
  .section-buttons .btn,
  .section-buttons .btn-morphing {
    margin-bottom: 10px;
  }
  .parallax .motto {
    top: 170px;
    margin-top: 0;
    font-size: 60px;
    width: 270px;
  }
}

.presentation .loader {
  opacity: 0;
  display: block;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1031;
  margin-left: -32px;
  margin-top: -32px;
}

.presentation .loader.visible {
  display: block;
  opacity: 1;
}

.presentation .modal-content {
  background-color: transparent;
  box-shadow: 0 0 0;
}

.presentation .modal-backdrop.in {
  opacity: 0.45;
}

.presentation .preload-image {
  display: none;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15), 0 0 1px 1px rgba(0, 0, 0, 0.1);
}

/*       Loading dots  */

/*      transitions */

.presentation .front,
.presentation .front:after,
.presentation .front .btn,
.logo-container .logo,
.logo-container .brand {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.presentation .section {
  padding: 100px 0;
}

.presentation .colors {
  padding: 100px 0;
}

.presentation > .description {
  padding-top: 20px;
}

.presentation .section-rotation {
  padding: 140px 0;
}

.presentation .section-images {
  padding: 80px 0;
}

.presentation .section-thin {
  padding: 0;
}

.presentation .section-pay {
  padding-top: 20px;
}

.presentation .colors {
  padding: 70px 0;
  z-index: 7;
  position: relative;
  margin-top: -300px;
}

.presentation .colors {
  border-top: 1px solid #dddddd;
}

.presentation .card-container {
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  -o-perspective: 800px;
  perspective: 800px;
  min-height: 500px;
  width: 300px;
  position: relative;
  margin-top: 90px;
}

.presentation .card-component {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
  height: 600px;
}

.presentation .card-component .front {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotateY(-28deg);
  -moz-transform: rotateY(-28deg);
  -o-transform: rotateY(-28deg);
  transform: rotateY(-28deg);
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  cursor: pointer;
  box-shadow: 10px 4px 14px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.presentation .front img {
  z-index: 2;
  position: relative;
}

.presentation .card-container:hover .front {
  top: -10px;
}

.presentation .card-component img {
  width: 100%;
}

.presentation .description .col-md-3 {
  width: 16%;
  margin-left: 4%;
}

.presentation .first-card {
  z-index: 6;
}

.presentation .second-card {
  z-index: 5;
}

.presentation .third-card {
  z-index: 4;
}

.presentation .fourth-card {
  z-index: 3;
}

.presentation h1,
.presentation h2 {
  font-weight: 200;
}

.presentation h4,
.presentation h5,
.presentation h6 {
  font-weight: 300;
}

.presentation h4 {
  font-size: 18px;
  line-height: 24px;
}

.presentation .info h4 {
  font-size: 24px;
  line-height: 28px;
}

.presentation .section-gray h1 small {
  color: #888888;
}

.presentation .color-container {
  text-align: center;
}

.presentation .color-container img {
  width: 100%;
  margin-bottom: 10px;
}

.presentation .circle-color {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: block;
  background-color: #cccccc;
  margin: 0 auto;
}

.presentation .circle-red {
  background-color: #ff3b30;
}

.presentation .circle-blue {
  background-color: #3472f7;
}

.presentation .circle-azure {
  background-color: #2ca8ff;
}

.presentation .circle-green {
  background-color: #05ae0e;
}

.presentation .circle-orange {
  background-color: #ff9500;
}

.presentation .section-gray-gradient {
  background: rgb(255, 255, 255);
  /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 25%,
    rgba(231, 231, 231, 1) 100%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(25%, rgba(255, 255, 255, 1)),
    color-stop(100%, rgba(231, 231, 231, 1))
  );
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 25%,
    rgba(231, 231, 231, 1) 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 25%,
    rgba(231, 231, 231, 1) 100%
  );
  /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 25%,
    rgba(231, 231, 231, 1) 100%
  );
  /* IE10+ */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 25%,
    rgba(231, 231, 231, 1) 100%
  );
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e7e7e7', GradientType=0);
  /* IE6-9 */
}

.presentation .section-black {
  background-color: #333;
}

.rotating-card-container {
  -webkit-perspective: 900px;
  -moz-perspective: 900px;
  -o-perspective: 900px;
  perspective: 900px;
  margin-bottom: 30px;
}

.rotating-card {
  -webkit-transition: all 1.3s;
  -moz-transition: all 1.3s;
  -o-transition: all 1.3s;
  transition: all 1.3s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  margin-top: 20px;
  position: relative;
  background: none repeat scroll 0 0 #ffffff;
  border-radius: 20px;
  color: #444444;
}

.rotating-card-container .rotate,
.rotating-card .back {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.rotating-card-container:hover .rotate {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.rotating-card .front,
.rotating-card .back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 3px 17px rgba(0, 0, 0, 0.15);
}

.rotating-card .front {
  z-index: 2;
}

.rotating-card .back {
  z-index: 3;
  height: 500px;
  width: 100%;
  display: block;
  padding: 0 15px;
  background-color: #e5e5e5;
}

.rotating-card .back-contaier {
  background-color: white;
  padding: 30px 15px;
}

.rotating-card .image {
  border-radius: 20px 20px 0 0;
}

.rotating-card-container,
.rotating-card .front,
.rotating-card .back {
  width: 100%;
  min-height: 500px;
  border-radius: 20px;
}

/*       Fix bug for IE      */

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rotating-card .front,
  .rotating-card .back {
    -ms-backface-visibility: visible;
    backface-visibility: visible;
  }
  .rotating-card .back {
    visibility: hidden;
    -ms-transition: all 0.2s cubic-bezier(0.92, 0.01, 0.83, 0.67);
  }
  .rotating-card .front {
    z-index: 4;
  }
  .rotating-card-container:hover .back {
    z-index: 5;
    visibility: visible;
  }
}

.fixed-section {
  top: 100px;
  max-height: 80vh;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  bottom: auto;
}

.fixed-section ul {
  padding: 0 !important;
}

.fixed-section ul li {
  list-style: none;
}

.fixed-section li a {
  font-size: 14px;
  padding: 2px;
  display: block;
  color: #666666;
}

.fixed-section li a.active {
  color: #00bbff;
}

/* prettyprint */

pre.prettyprint {
  background-color: #eee !important;
  margin-bottom: 30px;
  margin-top: 30px;
  padding: 20px !important;
  font-size: 13px;
  text-align: left;
  border-radius: 4px !important;
  border: 1px transparent !important;
}

.presentation .atv,
.presentation .str {
  color: #0d9814;
}

.presentation .tag,
.presentation .pln,
.presentation .kwd {
  color: #195cec;
}

.presentation .atn {
  color: #2c93ff;
}

.presentation .pln {
  color: #333;
}

.presentation .com {
  color: #999;
}

.presentation .text-white {
  color: #ffffff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.13);
}

.presentation .section-images .card-image {
  border-radius: 6px 6px 0 0;
  overflow: hidden;
  box-shadow: 0 -3px 8px rgba(0, 0, 0, 0);
}

.presentation .section-images .card-image .image {
  /*     border-radius: 6px; */
}

@media (max-width: 1200px) {
  .presentation .section-images .image img {
    width: 100%;
  }
}

.presentation .card-text-adjust {
  padding-left: 40px;
}

.presentation .info.info-separator {
  position: relative;
}

.presentation .info.info-separator:after {
  height: 100%;
  position: absolute;
  background-color: #ccc;
  width: 1px;
  content: "";
  right: -7px;
  top: 0;
}

.presentation .info li {
  padding: 5px 0;
  border-bottom: 1px solid #e5e5e5;
  color: #666666;
}

.presentation .info ul {
  width: 240px;
  margin: 10px auto;
}

.presentation .info li:last-child {
  border: 0;
}

/*      layer animation          */

.layers-container {
  display: block;
  margin-top: 50px;
  position: relative;
}

.layers-container img {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  text-align: center;
}

.section-black {
  background-color: #333;
}

#layerHover {
  top: 30px;
}

#layerImage {
  top: 50px;
}

#layerBody {
  top: 75px;
}

.animate {
  transition: 1.5s ease-in-out;
  -moz-transition: 1.5s ease-in-out;
  -webkit-transition: 1.5s ease-in-out;
}

.down {
  transform: translate(0, 45px);
  -moz-transform: translate(0, 45px);
  -webkit-transform: translate(0, 45px);
}

.down-2x {
  transform: translate(0, 90px);
  -moz-transform: translate(0, 90px);
  -webkit-transform: translate(0, 90px);
}

.navbar-default.navbar-small .logo-container .brand {
  color: #333333;
}

.navbar-transparent.navbar-small .logo-container .brand {
  color: #ffffff;
}

.navbar-default.navbar-small .logo-container .brand {
  color: #333333;
}

.section-thin {
  padding-bottom: 0;
}

.info.info-separator {
  position: relative;
}

.info.info-separator:after {
  height: 100%;
  position: absolute;
  background-color: #ccc;
  width: 1px;
  content: "";
  right: -7px;
  top: 0;
}

@media (max-width: 767px) {
  .info.info-separator:after {
    display: none;
  }
}

.info li {
  padding: 5px 0;
  border-bottom: 1px solid #e5e5e5;
  color: #666666;
}

.info ul {
  width: 240px;
  margin: 10px auto;
}

.info li:last-child {
  border: 0;
}

.payment-methods i {
  font-size: 28px;
  padding: 0 3px;
  width: 38px;
}

.payment-methods h4 {
  font-size: 18px;
  line-height: 38px;
}

.info .description .btn {
  font-weight: 500;
}

#buyButtonHeroes {
  margin-top: 31px;
}

.right-click {
  width: 100%;
  height: 100%;
  background: rgba(51, 51, 51, 0.8);
  position: fixed;
  z-index: 20000;
  display: none;
}

.onclick {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 20001;
}

.container-right-click {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.container-right-click .card-price#card-price-small {
  margin-top: 70px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  position: relative;
  z-index: 20003;
}

.container-right-click .card-price#card-price-big {
  margin-top: 40px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  position: relative;
  z-index: 20003;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes bounceInDown {
  0%,
  60%,
  75%,
  90%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes bounceInDown {
  0%,
  60%,
  75%,
  90%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

.container-right-click h4 {
  color: white;
  margin-top: 45px;
  font-weight: 200;
  margin-bottom: 0;
}

.icon-class {
  fill: #75c3b6;
}

.navbar-header {
  min-width: 135px;
}

#demo-navbar {
  border-radius: 0;
  margin-bottom: 0px;
}

.download-area {
  margin-top: 30px;
}

.sharing-area {
  margin-top: 50px;
}

.sharing-area .btn {
  margin-top: 14px;
}

/* nucleo icons  */

.demo-iconshtml {
  font-size: 62.5%;
}

.demo-icons body {
  font-size: 1.6rem;
  font-family: sans-serif;
  color: #333333;
  background: white;
}

.demo-icons a {
  color: #608cee;
  text-decoration: none;
}

.demo-icons header {
  text-align: center;
  padding: 100px 0 0;
}

.demo-icons header h1 {
  font-size: 2.8rem;
}

.demo-icons header p {
  font-size: 1.4rem;
  margin-top: 1em;
}

.demo-icons header a:hover {
  text-decoration: underline;
}

.demo-icons .nc-icon {
  font-size: 34px;
}

.demo-icons section {
  width: 90%;
  max-width: 1200px;
  margin: 50px auto;
}

.demo-icons section h2 {
  border-bottom: 1px solid #e2e2e2;
  padding: 0 0 1em 0.2em;
  margin-bottom: 1em;
}

.demo-icons ul::after {
  clear: both;
  content: "";
  display: table;
}

.demo-icons ul li {
  width: 25%;
  float: left;
  padding: 16px 0;
  text-align: center;
  border-radius: 0.25em;
  -webkit-transition: background 0.2s;
  -moz-transition: background 0.2s;
  transition: background 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

.demo-icons ul li:hover {
  background: #f4f4f4;
}

.demo-icons ul p,
.demo-icons ul em,
.demo-icons ul input {
  display: inline-block;
  font-size: 1rem;
  color: #999999;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.demo-icons ul p {
  padding: 20px 0 0;
  font-size: 12px;
  margin: 0;
}

.demo-icons ul p::selection,
.demo-icons ul em::selection {
  background: #608cee;
  color: #efefef;
}

.demo-icons ul em {
  margin-bottom: 8px;
  font-size: 12px;
}

.demo-icons ul em::before {
  content: "[";
}

.demo-icons ul em::after {
  content: "]";
}

.demo-icons ul input {
  text-align: center;
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  display: none;
}

@media only screen and (min-width: 768px) {
  .demo-icons ul li {
    width: 20%;
    float: left;
  }
}

@media only screen and (min-width: 1024px) {
  .demo-icons ul li {
    width: 12.5%;
    float: left;
    padding: 32px 0;
  }
}

/* for components and tutorial page */

/*pre.prettyprint{
        background-color: #FFFCF5;
        border: 0px;
        margin-bottom: 0;
        margin-top: 20px;
        padding: 20px;
        text-align: left;
    }*/

.atv,
.str {
  color: #75c3b6;
}

.tag,
.pln,
.kwd {
  color: #7a9e9f;
}

.atn {
  color: #68b3c8;
}

.pln {
  color: #333;
}

.com {
  color: #999;
}

.space-top {
  margin-top: 30px;
}

.area-line {
  /*border: 1px solid #999;*/
  border-left: 0;
  border-right: 0;
  color: #666;
  display: block;
  margin-top: 20px;
  padding: 8px 0;
  text-align: center;
}

.area-line a {
  color: #666;
}

.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
}

.example-pages {
  margin-top: 50px;
}

.main .section:first-of-type {
  position: relative;
  z-index: 2;
}

.profile-content {
  padding-top: 0 !important;
  position: relative;
  z-index: 2;
}

.btn-danger {
  background-color: #035a6d;
  border-color: #035a6d;
  color: #ffffff;
  opacity: 1;
}

.icon-danger {
  color: #035a6d;
}

.material-icons {
  display: inline-flex;
  vertical-align: top;
}
